<template>
  <div
      class="login-page"
  >
    <div class="login-form">
      <h2 class="display-2 font-weight-bold mb-3">
        Sign in
      </h2>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit="onSubmit"
      >
        <v-text-field
            v-model="username"
            :rules="usernameRules"
            label="Username"
            required
        ></v-text-field>

        <v-text-field
            v-model="password"
            :rules="passwordRules"
            label="Password"
            type="password"
            required
        ></v-text-field>

        <v-btn
            :disabled="!valid"
            outlined
            color="indigo"
            class="mt-2"
            type="submit"
            :loading="loading"
        >
          Submit
        </v-btn>
      </v-form>
    </div>

  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {LOGIN, LOGOUT} from "../store/modules/auth/types";

export default {
  name: "Login",
  data: () => ({
    valid: true,
    username: '',
    usernameRules: [
      v => !!v || 'Username is required'
    ],
    password: '',
    passwordRules: [
      v => !!v || 'Password is required'
    ],
    loading: false
  }),
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      isAuthenticated: state => state.auth.isAuthenticated
    })
  },

  methods: {
    ...mapActions({
      login: `auth/${LOGIN}`,
      logout: `auth/${LOGOUT}`
    }),

    validate() {
      this.$refs.form.validate()
    },
    onSubmit(e) {
      e.preventDefault();
      if (this.$refs.form.validate()) {
        const username = this.username;
        const password = this.password;

        this.login({username, password});
        this.loading = true;
      }
    },
  },
  watch: {
    isAuthenticated(newValue) {
      if (newValue) {
        this.loading = false;
        this.$router.push({name: "offers"});
      }
    },
    errors(newValue) {
      if (newValue && newValue.length) {
        this.loading = false;
      }
    }
  },
  created: function () {
    this.logout();
  }
}
</script>

<style scoped>

</style>